import React from 'react'

import { styled } from 'src/ui/theme'
import { Wrapper as Wrap, HBox, Col } from 'src/ui/layout'
import { Image as Img } from 'src/ui/atoms'
import { Body, Header } from 'src/ui/typography'

import { TReason } from './types'

const Wrapper = styled(Wrap)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`

const StyledCol = styled(Col)`
  justify-content: center;
`

type Props = TReason

export const DescReason = ({
  image,
  title,
  description,
  imagePosition = 'left',
}: Props) => {
  const Image = <Img fluid={image} height={298} />

  return (
    <Wrapper>
      {imagePosition === 'left' && Image}
      <StyledCol>
        <Header size="h2" as="h2">
          {title}
        </Header>
        <HBox />
        <Body>{description}</Body>
      </StyledCol>
      {imagePosition === 'right' && Image}
    </Wrapper>
  )
}
