import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

import { TReason } from './types'

type Props = TReason

export const MobReason = ({ image, title, description }: Props) => (
  <Hero background={image} title={title} textWidth={100}>
    <HBox />
    <Body>{description}</Body>
  </Hero>
)
