import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { VideoReason as Desc } from './desk'
import { VideoReason as Mob } from './mob'
import { TVideoReason } from './types'

type Props = TVideoReason

export const VideoBlock = (props: Props) => {
  const { isMobile } = useDimensions()
  return isMobile ? <Mob {...props} /> : <Desc {...props} />
}
