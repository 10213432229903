import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'
import { HERO_TEXT, HERO_TITLE } from './constants'

type Props = {
  images: TImages
}

export const LoyaltyHero = ({ images }: Props) => (
  <Hero background={images['loyalty-hero']} title={HERO_TITLE} adornment>
    <HBox />
    <Body>{HERO_TEXT}</Body>
  </Hero>
)
