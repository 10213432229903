import React from 'react'

import { HBox } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'
import { HERO_TEXT, HERO_TITLE } from './constants'

type Props = {
  images: TImages
}

export const LoyaltyHero = ({ images }: Props) => (
  <HeroDesktopSecondary
    contentPosition="bottom-left"
    background={images['loyalty-hero']}
    contentWidth={50}
    height={538}
    title={HERO_TITLE}
    adornment
    titleSize="h2"
    titleAs="h1"
  >
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '640px',
      }}
    >
      {HERO_TEXT}
    </Body>
  </HeroDesktopSecondary>
)
