import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

import { TVideoReason } from './types'

type Props = TVideoReason

export const VideoReason = ({ videoId, title, description }: Props) => (
  <Hero noPointerEvents videoId={videoId}  title={title} textHeight={202}>
    <HBox />
    <Body>{description}</Body>
  </Hero>
)
