import React from 'react'

import { useDimensions } from 'src/ui/dimensions'
import { TReason } from './types'
import { MobReason } from './mob'
import { DescReason } from './desk'

type Props = TReason

export const ImageBlock = (props: Props) => {
    const { isMobile } = useDimensions()
    return isMobile ? <MobReason {...props} /> : <DescReason {...props} />

}
